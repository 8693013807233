'use client'
import { configureStore } from "@reduxjs/toolkit";
import searchResultFilter from "./searchResultSlice/searchResultSlice"
import featuredOffplanProperty from "./FeaturedOffplanPropertySlice/featuredOffplanPropertySlice";
import allTeams from "./team/allTeamsSlice";
import contactModalReducer from "./contactModal/contactModalSlice";

export default configureStore({
    reducer:{
        searchResultFilter:searchResultFilter,
        featuredOffplanProperty:featuredOffplanProperty,
        allTeams,
        contactModalRedux:contactModalReducer
    }
})