"use client";
import dynamic from "next/dynamic";
import { Provider } from "react-redux";
import store from "./store";
import Header from "../_components/Header/Header";
import { useOnInteraction } from "../_components/useOnInteraction/useOnInteraction";
const Footer = dynamic(() => import("../_components/Footer/Footer"));
export const ReduxProvider = ({ children }) => {
  const isUserInteracted = useOnInteraction()
  return (
    <Provider store={store}>
    
      <Header />
      {children}
      {isUserInteracted && <Footer />} 
    </Provider>
  );
};
